import React from "react"

import Layout from "../components/Layout"

export default function Partners() {
  return (
    <Layout title="Partners">
      <div className="abouts segments-page default-container">
        <div className="container">
          <div className="about">
            <div className="content b-shadow">
              <div className="text">
                <h2>Overview</h2>
                <div className="social-link"></div>
                <p>
                  We are a customer-oriented exporter of premium quality product
                  lines to a worldwide customer base. To meet the needs of our
                  customers from different regions, we continue to establish
                  ongoing partnerships with manufacturers and distributors,
                  forming them as a vast network of resources to help us achieve
                  our business goals.
                </p>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="container">
          <div className="about">
            <div className="content b-shadow">
              <div className="text">
                <h2>Distributors</h2>
                <div className="social-link"></div>
                <p>
                  If you are a distributor interested in one of our product
                  lines, please <a href="/contact.html">contact us</a> to learn
                  how you can begin distributing our products in your region.
                  Alternatively, if you would like to distribute a product line
                  that we don't currently carry, we can assist in obtaining it
                  from the manufacturer.
                </p>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="container">
          <div className="about">
            <div className="content b-shadow">
              <div className="text">
                <h2>Manufacturers</h2>
                <div className="social-link"></div>
                <p>
                  We are always looking for new product lines to carry. If you
                  are a United States based manufacturer seeking to export your
                  goods into new markets, please{" "}
                  <a href="/contact.html">contact us</a> to learn how we can
                  help. We maintain a vast array of distributors in foreign
                  countries. In situations where we have no existing
                  distributors, we will work diligently on your behalf to find
                  new ones by attending tradeshows and networking in the local
                  country.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
